import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer/Footer';
import { headData } from '../data/data';
import '../style/text-page.scss';

const renderPrivacy = () => {
  return (
    <div className="text-container">
      <h1>プライバシーポリシー</h1>
      <h2>当サイトが使用しているアクセス解析ツールについて</h2>
      <p>
        当サイトでは、Google によるアクセス解析ツール「Google アナリティクス」を利用しています。
        <br />
        この Google アナリティクスはトラフィックデータの収集のために Cookie を使用しています。
        <br />
        このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
        <br />
        この機能は Cookie
        を無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
        <br />
        この規約に関して、詳しくは
        <a href="https://marketingplatform.google.com/about/analytics/terms/jp/">こちら</a>、または
        <a href="https://policies.google.com/technologies/partner-sites?hl=ja">こちら</a>
        をクリックしてください。
      </p>
      <h2>情報の外部送信について</h2>
      <p>
        当サイトは、アクセス数の解析や広告の配信を目的として、下記のツールを採用しています。これによりツール提供事業者に対してサイト訪問者の情報が送信されています。
      </p>
      <hr />
      <h3>アクセス解析</h3>
      <p>
        当サイトは、訪問者数や訪問時間、使用環境、地域別の訪問者数、訪問元などを解析し、サービス改善や広告の有効性を評価するために、以下のサービスに情報を転送しています。
      </p>
      <div>
        <p>Google アナリティクス / Google LLC</p>
        <p>
          Google
          アナリティクスは、各ページの訪問回数や訪問時間、使用環境、地域別の訪問者数、訪問元や検索キーワード等を解析する機能を提供します。
        </p>
        <p>
          <a href="https://policies.google.com/privacy?hl=ja">Googleのプライバシーポリシー</a>
        </p>
      </div>
      <div>
        <p>転送される情報</p>
        <p>・Cookieなどの利用者を識別するための識別子</p>
        <p>・閲覧しているURLやサイトタイトルなどの情報とその時刻</p>
        <p>・ブラウザやOS、端末の種別、画面サイズやIPアドレスなどの利用環境に関する情報</p>
      </div>
      <hr />
      <h3>広告配信</h3>
      <p>
        当サイトは、訪問者の行動や特性に基づいた広告を配信し、その効果を評価するために、以下のサービスにパーソナルデータを転送しています。
      </p>
      <div>
        <p>Google AdSence / Google LLC</p>
        <p>
          Google
          AdSenceは、Googleのサービスや他の第三者サイトでの広告配信、広告のパーソナライズ、広告表示の制限、広告効果の評価等のために情報を利用しています。
        </p>
        <p>
          <a href="https://policies.google.com/privacy?hl=ja">Googleのプライバシーポリシー</a>
        </p>
      </div>
      <div>
        <p>転送される情報</p>
        <p>・Cookieなどの利用者を識別するための識別子</p>
        <p>・閲覧しているURLやサイトタイトルなどの情報とその時刻</p>
        <p>・ブラウザやOS、端末の種別、画面サイズやIPアドレスなどの利用環境に関する情報</p>
      </div>
      <h2>プライバリーポリシーの変更について</h2>
      <p>
        当サイトのプライバシーポリシーは、サイト運営上の環境の変化などに対応していくため、都度必要な変更を行います。
        <br />
        改定を行った場合には、サイト上に掲示することでお知らせします。
        <br />
        また、変更後のプライバシーポリシーについては、当サイトに掲載したときから効力が生じるものとします。
      </p>
      <h2>個人情報を収集・利用する目的</h2>
      <p>当サイトではユーザの問い合わせに対応する場合に限り、ユーザの連絡先を収集・利用します。</p>
      <h2>お問い合わせについて</h2>
      <p>
        本ポリシーに関するお問い合わせは、こちらのお問い合わせフォームよりお願いいたします。
        <br />
        (お問合せフォームはGoogle Formを利用しています)
        <br />
        <span className="d-flex mt-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="cta-btn cta-btn--resume"
            href="https://docs.google.com/forms/d/e/1FAIpQLScIPEqNUyI_93_A64Yw-M92nCQG32TFgKr1-Zkp0vFu6CgJeA/viewform"
            style={{ background: '#00cdac', border: 'solid 1px #00cdac' }}
          >
            問い合わせフォームを開く
          </a>
        </span>
      </p>
    </div>
  );
};

export default () => {
  const { title, lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} | プライバリーポリシー</title>
        <html lang={lang || 'en'} />
        <meta name="description" content="プライバリーポリシーに関するページとなります。" />
      </Helmet>

      <Container>{renderPrivacy()}</Container>
      <Footer />
    </>
  );
};
